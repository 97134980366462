/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const getStatusColor = (status: string) => {
  if (status === 'published' || status === 'active') {
    return 'green';
  }
  if (status === 'draft') {
    return 'orange';
  }
  return 'red';
};

const projectColumns = (): IDataTableColumn[] => [
  {
    name: 'Project Number',
    selector: 'project_number',
    sortable: true,
    cell: (row) => {
      return (
        <div style={{ cursor: 'copy' }} onClick={() => navigator.clipboard.writeText(row?.project_number)}>
          {row.project_number}
        </div>
      );
    },
  },
  {
    name: 'Project Name',
    selector: 'name',
    sortable: true,
    cell: (row) => {
      return (
        <div style={{ cursor: 'copy' }} onClick={() => navigator.clipboard.writeText(row?.name)}>
          {row.name}
        </div>
      );
    },
  },
  {
    name: 'Client Name',
    selector: 'client_name',
    sortable: true,
  },
  {
    name: 'Start Date',
    selector: 'start_date',
    sortable: true,
  },
  {
    name: 'End Date',
    selector: 'end_date',
    sortable: true,
  },
  {
    name: 'Project Manager',
    selector: 'project_manager_name',
    sortable: true,
  },
  {
    name: 'Recruiter',
    selector: 'recruiter_name',
    sortable: true,
  },
  {
    name: 'TI Recruiter',
    selector: 'ti_recruiter_name',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: getStatusColor(status),
        }}
      >
        {status}
      </div>
    ),
  },
];

// We create the columns with a function to avoid having to add types to every-single column
// Afterward, we export the resulting columns by calling the function
export const projectColumnsFinancialFunction = (): IDataTableColumn[] => [
  {
    name: 'Project Number',
    selector: 'project_number',
    sortable: true,
    cell: (row) => {
      return (
        <div style={{ cursor: 'copy' }} onClick={() => navigator.clipboard.writeText(row?.project_number)}>
          {row.project_number}
        </div>
      );
    },
  },
  {
    name: 'Insighter Honoraria',
    selector: 'insighter_payments_sum',
    sortable: true,
    cell: (row) => {
      return <>{`$${row.insighter_payments_sum}`}</>;
    },
  },
  {
    name: 'Insighter Travel',
    selector: 'insighter_travel_sum',
    sortable: true,
    cell: (row) => {
      return <>{`$${row.insighter_travel_sum}`}</>;
    },
  },
  {
    name: 'Insighter Other',
    selector: 'insighter_other_sum',
    sortable: true,
    cell: (row) => {
      return <>{`$${row.insighter_other_sum}`}</>;
    },
  },
  {
    name: 'Referral Program',
    selector: 'invitation_program_sum',
    sortable: true,
    cell: (row) => {
      return <>{`$${row.invitation_program_sum}`}</>;
    },
  },
  {
    name: 'Insighter Brand Partner',
    selector: 'insighter_brand_partner_sum',
    sortable: true,
    cell: (row) => {
      return <>{`$${row.insighter_brand_partner_sum}`}</>;
    },
  },
  {
    name: 'Qualtrics Programming',
    selector: 'ads_sum',
    sortable: true,
    cell: (row) => {
      return <>{`$${row.qualtrics_programming_sum}`}</>;
    },
  },
  {
    name: 'Ads',
    selector: 'ads_sum',
    sortable: true,
    cell: (row) => {
      return <>{`$${row.ads_sum}`}</>;
    },
  },
  {
    name: 'Other Expenses',
    selector: 'expenses_payments_sum',
    sortable: true,
    cell: (row) => {
      return <>{`$${row.expenses_payments_sum}`}</>;
    },
  },
  {
    name: 'Total Marketing Budget',
    selector: 'marketing_budget',
    sortable: true,
    cell: (row) => {
      return <>{`$${row.marketing_budget}`}</>;
    },
  },
  {
    name: 'Marketing Budget Remaining',
    selector: 'marketing_budget_remaining',
    sortable: true,
    cell: (row) => {
      return <>{`$${row.marketing_budget_remaining}`}</>;
    },
  },
  {
    name: 'Number of Qualtrics Responses',
    selector: 'number_of_qualtrics_responses',
    sortable: true,
  },
];

// Export the resulting columns by calling the function
export const projectColumnsFinancial = projectColumnsFinancialFunction();

export default projectColumns;
