import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { InvitationOpportunity } from '../model';

interface DownloadPicAndDescriptionProps {
  invitationOpportunity: InvitationOpportunity | undefined;
  whichPic: string;
  description: string;
}

const DownloadPicAndDescription: React.FC<DownloadPicAndDescriptionProps> = (props) => {
  const { invitationOpportunity, whichPic, description } = props;

  const onDownloadImage = (imgSrc: string) => {
    const link = document.createElement('a');
    link.href = imgSrc;
    link.download = `${invitationOpportunity?.name || 'image'}-${description}.png`;
    link.target = '_blank'; // fallback: open in new tab if download fails
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Grid container style={{ padding: '20px' }}>
      <Grid item xs={12}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <img
          id="image-to-download"
          src={whichPic}
          alt="Default"
          style={{ width: '100px', height: '100px', cursor: 'pointer' }}
          onClick={() => onDownloadImage(whichPic)}
        />
        <Typography variant="h6" gutterBottom>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default DownloadPicAndDescription;
