import React from 'react';
import { Button, FormLayout } from '@shopify/polaris';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { InvitationPartnersContext } from '../contexts/InvitationPartnersContext';
import { InvitationPartner, InvitationPartnerFormValues } from '../model';
import { InvitationOpportunity } from '../../invitationOpportunities/model';

interface InvitationPartnerProps {
  invitationPartner?: InvitationPartner;
  invitationOpportunity?: InvitationOpportunity;
}

const InvitationPartnerForm: React.FC<InvitationPartnerProps> = (props) => {
  const { invitationPartnerId, invitationOpportunityId } = useParams<Record<string, string>>();
  const { invitationPartner, invitationOpportunity } = props;
  const { createInvitationPartner, updateInvitationPartner, errorMessage, deleteInvitationPartner } =
    React.useContext(InvitationPartnersContext);

  const [formValues, setFormValues] = React.useState<InvitationPartnerFormValues>({});

  React.useEffect(() => {
    // Set default values for new invitation partner
    if (invitationOpportunity) {
      setFormValues({
        invitation_quota: '0',
        inviter_amount: invitationOpportunity?.default_inviter_amount,
        invitee_amount: invitationOpportunity?.default_invitee_amount,
        invitation_opportunity_id: invitationOpportunityId,
      });
    } else {
      // Set existing values for invitation partner edit
      setFormValues({
        invitation_quota: invitationPartner?.invitation_quota,
        inviter_amount: invitationPartner?.inviter_amount,
        invitee_amount: invitationPartner?.invitee_amount,
        invitation_opportunity_id: invitationOpportunityId,
        insighter_email: undefined,
      });
    }
  }, [invitationOpportunity, invitationPartner, invitationOpportunityId]);

  const handleCreate = () => {
    createInvitationPartner(formValues);
  };

  const handleUpdate = () => {
    updateInvitationPartner(invitationPartnerId, formValues);
  };

  const handleDelete = () => {
    deleteInvitationPartner(invitationPartnerId, formValues);
  };

  return (
    <FormLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 className="Polaris-Heading">Referral Partner</h2>
        </Grid>
        <>
          {invitationPartner?.id ? (
            <Grid item xs={12}>
              <FormInput label="Insighter Name" value={invitationPartner.insighter_name} disabled />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <FormInput
                label="Insighter Email"
                value={formValues.insighter_email}
                onChange={(value) => setFormValues({ ...formValues, insighter_email: value })}
              />
            </Grid>
          )}
        </>
        <Grid item xs={12}>
          <FormInput
            label="Amount Per Referral"
            type="money"
            value={formValues.inviter_amount}
            onChange={(value) => {
              const formattedValue = value.charAt(0) === '$' ? value.slice(1) : value;
              setFormValues({ ...formValues, inviter_amount: formattedValue });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Invitee Amount"
            type="money"
            value={formValues.invitee_amount}
            onChange={(value) => {
              const formattedValue = value.charAt(0) === '$' ? value.slice(1) : value;
              setFormValues({ ...formValues, invitee_amount: formattedValue });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Referral Quota"
            value={formValues.invitation_quota}
            onChange={(value) => setFormValues({ ...formValues, invitation_quota: value })}
          />
        </Grid>
      </Grid>

      {errorMessage && <p>{errorMessage}</p>}
      <>
        {invitationPartner?.id ? (
          <>
            <Button primary onClick={handleUpdate}>
              Update Referral Partner
            </Button>
            {invitationPartner?.invitations_completed === 0 && (
              <span style={{ marginLeft: '8px' }}>
                <Button onClick={handleDelete}>Delete Referral Partner</Button>
              </span>
            )}
          </>
        ) : (
          <Button primary onClick={handleCreate}>
            Add Referral Partner
          </Button>
        )}
      </>
    </FormLayout>
  );
};

export default InvitationPartnerForm;
