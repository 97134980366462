import React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Loading, Toast, Icon } from '@shopify/polaris';
import Grid from '@material-ui/core/Grid';
import { CircleTickMajor, CircleAlertMajor, CircleDisabledMajor } from '@shopify/polaris-icons';
import { ProjectsContext } from '../contexts/ProjectsContext';
import DetailPageSkeleton from '../../loading/components/DetailPageSkeleton';
import ProjectChecklist from '../components/ProjectChecklist';
import { ProjectChecklistFormValues } from '../model';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const ProjectWrapupChecklist: React.FunctionComponent = () => {
  const [projectFetched, setProjectFetched] = React.useState(false);
  const [formValues, setFormValues] = React.useState<ProjectChecklistFormValues>({});

  const { projectId } = useParams<Record<string, string>>();
  const {
    project,
    fetchProject,
    projectLoading,
    projectSubmitting,
    fetchProjectWrapUpChecklist,
    updateProjectWrapUpChecklist,
    completeProjectWrapUpChecklist,
    projectWrapUpChecklist,
  } = React.useContext(ProjectsContext);

  React.useEffect(() => {
    if (!projectFetched) {
      fetchProject(projectId);
      fetchProjectWrapUpChecklist(projectId);
      setProjectFetched(true);
    }
  }, [projectFetched, fetchProject, projectId, fetchProjectWrapUpChecklist]);

  const [toastActive, setToastActive] = React.useState(false);
  const toggleToastActive = React.useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const loadingMarkup = projectLoading ? <Loading /> : null;
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  React.useEffect(() => {
    setFormValues({
      project_id: projectWrapUpChecklist?.project_id,
      calendly_status: projectWrapUpChecklist?.calendly_status,
      qualtrics_status: projectWrapUpChecklist?.qualtrics_status,
      linktree_status: projectWrapUpChecklist?.linktree_status,
      reporting_status: projectWrapUpChecklist?.reporting_status,
      expense_invitation_partners_status: projectWrapUpChecklist?.expense_invitation_partners_status,
      expense_seamless_status: projectWrapUpChecklist?.expense_seamless_status,
      expense_programming_status: projectWrapUpChecklist?.expense_programming_status,
      expense_social_media_ads_status: projectWrapUpChecklist?.expense_social_media_ads_status,
      report_total_qualtrics_expense_status: projectWrapUpChecklist?.report_total_qualtrics_expense_status,
      report_total_qualtrics_responses_status: projectWrapUpChecklist?.report_total_qualtrics_responses_status,
      pull_final_qualtrics_responses_status: projectWrapUpChecklist?.pull_final_qualtrics_responses_status,
      giftcards_for_completed_screeners: projectWrapUpChecklist?.giftcards_for_completed_screeners,
      giftcards_for_completed_tech_check: projectWrapUpChecklist?.giftcards_for_completed_tech_check,
    });
  }, [projectWrapUpChecklist, setFormValues]);

  const statusBadge = () => {
    if (project?.status === 'draft') {
      return <Badge status="warning">Draft</Badge>;
    }
    if (project?.status === 'published') {
      return <Badge status="success">Published</Badge>;
    }
    return <Badge status="critical">Inactive</Badge>;
  };

  const successIndicator = (showSuccess: boolean, showDisabled: boolean) => {
    if (showSuccess) {
      return <Icon source={CircleTickMajor} color="success" />;
    }

    if (showDisabled) {
      return <Icon source={CircleDisabledMajor} color="success" />;
    }

    return <Icon source={CircleAlertMajor} color="critical" />;
  };

  // const updateChecklist = (key: string, value: string) => {
  //   updateProjectWrapUpChecklist(project?.id, { [key]: value });
  // };

  const updateChecklist = () => {
    updateProjectWrapUpChecklist(projectId, formValues);
  };

  const submitChecklistCard = {
    title: 'Complete Project',
    description: 'After completing the tasks above, mark the project as complete to set its status as inactive',
    check: {
      label: 'Project Complete',
      icon: successIndicator(projectWrapUpChecklist?.project_status !== null, false),
    },
    button: {
      label: 'Mark Complete',
      action: () => completeProjectWrapUpChecklist(project?.id),
      loading: projectSubmitting,
    },
  };

  const checklistCards = [
    {
      genericCard: false,
      title: 'Payments',
      description: "Approve the project's payments.",
      checkIcons: [
        {
          label: 'Payments',
          icon: successIndicator(!project?.incomplete_payments, false),
        },
      ],
      customLinkActive: project?.incomplete_payments,
      customLinkLabel: 'View Payments',
      customLinkUrl: '/admin/payments',
    },
    {
      genericCard: true,
      title: 'Expenses',
      description: 'Confirm the project expenses.',
      checkIcons: [
        {
          label: 'Referral Partners',
          checked: formValues?.expense_invitation_partners_status === 'complete',
          disabled: formValues?.expense_invitation_partners_status === 'not needed',
          databaseKey: 'expense_invitation_partners_status',
        },
        {
          label: 'Seamless',
          checked: formValues?.expense_seamless_status === 'complete',
          disabled: formValues?.expense_seamless_status === 'not needed',
          databaseKey: 'expense_seamless_status',
        },
        {
          label: 'Programming',
          checked: formValues?.expense_programming_status === 'complete',
          disabled: formValues?.expense_programming_status === 'not needed',
          databaseKey: 'expense_programming_status',
        },
        {
          label: 'Social Media Ads',
          checked: formValues?.expense_social_media_ads_status === 'complete',
          disabled: formValues?.expense_social_media_ads_status === 'not needed',
          databaseKey: 'expense_social_media_ads_status',
        },
      ],
    },
    {
      genericCard: true,
      title: 'Linktree',
      description: "Clean up the project's Linktree page.",
      checkIcons: [
        {
          label: 'Linktree Cleaned Up',
          checked: formValues?.linktree_status === 'complete',
          disabled: formValues?.linktree_status === 'not needed',
          databaseKey: 'linktree_status',
        },
      ],
    },
    {
      genericCard: true,
      title: 'Qualtrics',
      checkIcons: [
        {
          label: 'Turn off Qualtrics link',
          checked: formValues?.qualtrics_status === 'complete',
          diabled: formValues?.qualtrics_status === 'not needed',
          databaseKey: 'qualtrics_status',
        },
      ],
    },
    {
      genericCard: true,
      title: 'Calendly',
      checkIcons: [
        {
          label: 'Turn off Calendly link',
          checked: formValues?.calendly_status === 'complete',
          disabled: formValues?.calendly_status === 'not needed',
          databaseKey: 'calendly_status',
        },
      ],
    },
    {
      genericCard: false,
      title: 'Opportunities',
      description: 'Set project opportunities to inactive.',
      checkIcons: [
        {
          label: 'Opportunities Inactive',
          icon: successIndicator(projectWrapUpChecklist?.opportunities_inactive, false),
        },
      ],
      customLinkActive: !projectWrapUpChecklist?.opportunities_inactive,
      customLinkLabel: 'View Opportunites',
      customLinkUrl: `/admin/projects/${project.id}`,
    },
    {
      genericCard: true,
      title: 'Reporting',
      description: 'Generate project report.',
      checkIcons: [
        {
          label: 'Report Completed',
          checked: formValues?.reporting_status === 'complete',
          disabled: formValues?.reporting_status === 'not needed',
          databaseKey: 'reporting_status',
        },
      ],
    },
    {
      genericCard: true,
      title: 'Report Total Qualtrics Responses',
      description: '',
      checkIcons: [
        {
          label: '$2.00 x n = $ expense',
          checked: formValues?.report_total_qualtrics_expense_status === 'complete',
          disabled: formValues?.report_total_qualtrics_expense_status === 'not needed',
          databaseKey: 'report_total_qualtrics_expense_status',
        },
        {
          label: 'Report Completed',
          checked: formValues?.report_total_qualtrics_responses_status === 'complete',
          disabled: formValues?.report_total_qualtrics_responses_status === 'not needed',
          databaseKey: 'report_total_qualtrics_responses_status',
        },
      ],
    },
    {
      genericCard: true,
      title: 'Pull Final Quatrics Responses',
      description: '',
      checkIcons: [
        {
          label: 'Report Completed',
          checked: formValues?.pull_final_qualtrics_responses_status === 'complete',
          disabled: formValues?.pull_final_qualtrics_responses_status === 'not needed',
          databaseKey: 'pull_final_qualtrics_responses_status',
        },
      ],
    },
    {
      genericCard: true,
      title: 'Gift Cards',
      description: '',
      checkIcons: [
        {
          label: 'Three Randomly Awarded Gift Cards for Fully-Completed Screeners',
          checked: formValues?.giftcards_for_completed_screeners === 'complete',
          disabled: formValues?.giftcards_for_completed_screeners === 'not needed',
          databaseKey: 'giftcards_for_completed_screeners',
        },
        {
          label: 'One Randomly Awarded Gift Cards for a Person who Completed Tech Check and Showed Up on Time',
          checked: formValues?.giftcards_for_completed_tech_check === 'complete',
          disabled: formValues?.giftcards_for_completed_tech_check === 'not needed',
          databaseKey: 'giftcards_for_completed_tech_check',
        },
      ],
    },
  ];

  // ---- Page markup ----
  const actualPageMarkup = (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title={`${project?.name} Wrap-Up Checklist`}
        badge={{ label: project?.status }}
        backButton={{ label: project?.name, link: `/admin/projects/${project?.id}` }}
      />
      <Grid item xs={12}>
        <ProjectChecklist
          checklistCards={checklistCards}
          submitChecklistCard={submitChecklistCard}
          updateChecklist={updateChecklist}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </Grid>
    </Grid>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DetailPageSkeleton />;

  const pageMarkup = projectLoading || !project?.id ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default ProjectWrapupChecklist;
