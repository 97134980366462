import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Badge, Button, Card, Layout, Loading, Page, Stack } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import DetailPageSkeleton from '../../loading/components/DetailPageSkeleton';
import { InsightersContext } from '../contexts/InsightersContext';
import BackButton from '../../navigation/components/BackButton';

const InsighterImpersonation: React.FunctionComponent = () => {
  const { insighterId } = useParams<Record<string, string>>();
  const history = useHistory();
  const { insighter, fetchInsighter, insighterLoading } = React.useContext(InsightersContext);
  const [insighterFetched, setInsighterFetched] = React.useState(false);
  const [healthcareProviderStatus, setHealthCareProviderStatus] = React.useState(false);
  const [healthcareProviderStatusVerified, setHealthCareProviderStatusVerified] = React.useState(false);
  // const [noteModalOpen, setNoteModalOpen] = React.useState(false);
  // const [editNoteModalOpen, setEditNoteModalOpen] = React.useState(false);
  // const [activeNote, setActiveNote] = React.useState(null);

  React.useEffect(() => {
    if (!insighterFetched) {
      fetchInsighter(insighterId);
      setInsighterFetched(true);
    }
  }, [insighterFetched, fetchInsighter, insighterId]);

  React.useEffect(() => {
    if (insighter?.insighter_roles?.length > 0 && !healthcareProviderStatusVerified) {
      insighter?.insighter_roles?.forEach((role) => {
        if (role.hcp_role) {
          setHealthCareProviderStatus(true);
        }
      });
      setHealthCareProviderStatusVerified(true);
    }
  }, [insighter, healthcareProviderStatusVerified]);

  const loadingMarkup = insighterLoading ? <Loading /> : null;

  const getBadgeStatus = (badgeColor: string) => {
    if (badgeColor === 'green') {
      return 'success';
    }
    if (badgeColor === 'orange') {
      return 'warning';
    }
    return 'critical';
  };

  const badges = [];

  insighter?.insighter_tags?.forEach((insighterTag) => {
    if (insighterTag?.badge_color === null || insighterTag?.badge_color === 'grey') {
      badges.push(
        <>
          <Badge>{insighterTag.name}</Badge>
          <span style={{ marginLeft: 7 }} />
        </>,
      );
    } else {
      badges.push(
        <>
          <Badge status={getBadgeStatus(insighterTag?.badge_color)}>{insighterTag.name}</Badge>
          <span style={{ marginLeft: 7 }} />
        </>,
      );
    }
  });

  // ---- Page markup ----
  const actualPageMarkup = (
    <>
      <BackButton pageAction url={`/admin/insighters/${insighter.id}`} />
      <Page
        fullWidth
        title={`${insighter.name} (${insighter.uuid})`}
        titleMetadata={
          <>
            {badges}
            <Badge status="success">{insighter.status}</Badge>
          </>
        }
        additionalMetadata={`Account Created ${insighter.sign_up_time}`}
      >
        <Layout>
          <Layout.Section>
            {insighter.archived_profile ? (
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Card sectioned title="Available Pages">
                    <Grid container>
                      <Grid item xs={3}>
                        <Card.Section title="Account Status">
                          <p style={{ fontWeight: 700, color: 'red' }}>ARCHIVED ACCOUNT</p>
                        </Card.Section>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Card sectioned title="Available Pages">
                    <Grid container spacing={3}>
                      <Grid item>
                        <Button primary onClick={() => history.push(`/dashboard/${insighterId}`)}>
                          View Dashboard Page
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button primary onClick={() => history.push(`/opportunities/${insighterId}`)}>
                          View Opportunities Page
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button primary onClick={() => history.push(`/invitations/${insighterId}`)}>
                          View Referrals Page
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button primary onClick={() => history.push(`/payments/${insighterId}`)}>
                          View Payments Page
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            )}
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DetailPageSkeleton />;
  const pageMarkup = insighterLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default InsighterImpersonation;
