import React from 'react';
import { Card, Button, FormLayout } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import axios from '../../utils/axios.utils';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import UngroupedAutocompleteInput from '../../form/components/UngroupedAutocompleteInput';
import { InvitationOpportunitiesContext } from '../contexts/InvitationOpportunitiesContext';
import { InvitationOpportunity, InvitationOpportunityFormValues } from '../model';

interface InvitationOpportunityProps {
  invitationOpportunity?: InvitationOpportunity;
}

const invitationTypeChoices = [
  { label: 'Sign-Up', value: 'Sign-Up' },
  { label: 'Interview', value: 'Interview' },
  { label: 'Survey', value: 'Survey' },
];

const InvitationOpportunityForm: React.FC<InvitationOpportunityProps> = (props) => {
  const { invitationOpportunity } = props;

  const {
    createInvitationOpportunity,
    updateInvitationOpportunity,
    deleteInvitationOpportunity,
    formOptions,
    formError,
  } = React.useContext(InvitationOpportunitiesContext);
  const [projectOptions, setProjectOptions] = React.useState();
  const [phaseOptions, setPhaseOptions] = React.useState([]);
  const [opportunityOptions, setOpportunityOptions] = React.useState();
  const [formValues, setFormValues] = React.useState<InvitationOpportunityFormValues>({});

  React.useEffect(() => {
    setFormValues({
      name: invitationOpportunity?.name,
      description: invitationOpportunity?.description,
      invitation_quota: invitationOpportunity?.invitation_quota,
      invitations_completed: invitationOpportunity?.invitations_completed,
      default_inviter_amount: invitationOpportunity?.default_inviter_amount,
      default_invitee_amount: invitationOpportunity?.default_invitee_amount,
      start_date: invitationOpportunity?.start_date,
      end_date: invitationOpportunity?.end_date,
      social_media_title: invitationOpportunity?.social_media_title,
      social_media_description: invitationOpportunity?.social_media_description,
      status: invitationOpportunity?.status,
      invitation_type: invitationOpportunity?.invitation_type,
      project_id: invitationOpportunity?.project_id,
      phase_id: invitationOpportunity?.phase_id,
      phase_names: invitationOpportunity?.phase_names || [],
      opportunity_id: invitationOpportunity?.opportunity_id,
      learn_more: invitationOpportunity?.learn_more,
      sample_message: invitationOpportunity?.sample_message,
      custom_query_parameter: invitationOpportunity?.custom_query_parameter,
      update_status: invitationOpportunity?.update_status,
      inviter_award_type: invitationOpportunity?.inviter_award_type,
    });

    axios.get<string, any>(`invitation_opportunities/new.json`).then((response) => {
      setProjectOptions(response?.data?.project_options);
    });

    if (invitationOpportunity?.project_id) {
      axios
        .get<string, any>(`invitation_opportunities/new?project_id=${invitationOpportunity?.project_id}`)
        .then((response) => {
          setOpportunityOptions(response?.data?.opportunity_options);
          console.log(response?.data?.phase_options);
          setPhaseOptions(response?.data?.phase_options);
        });
    }
  }, [invitationOpportunity]);

  const handleCreate = () => {
    createInvitationOpportunity(formValues);
  };

  const handleUpdate = () => {
    updateInvitationOpportunity(invitationOpportunity.id, formValues);
  };

  const handleDelete = () => {
    deleteInvitationOpportunity(invitationOpportunity.id);
  };

  const handleProjectChange = async (value: string) => {
    await axios.get<string, any>(`invitation_opportunities/new?project_id=${value}`).then((response) => {
      setPhaseOptions(response?.data?.phase_options);
      setOpportunityOptions(response?.data?.opportunity_options);
      setFormValues({ ...formValues, project_id: value });
    });
  };

  return (
    <FormLayout>
      <Card sectioned>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h2 className="Polaris-Heading">Invitation Opportunity</h2>
          </Grid>
          <Grid item xs={12}>
            <FormInput
              label="Invitation Name"
              value={formValues?.name}
              onChange={(value) => setFormValues({ ...formValues, name: value })}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              label="Invitation Description"
              rows={3}
              value={formValues?.description}
              onChange={(value) => setFormValues({ ...formValues, description: value })}
              multiline
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              label="Default Inviter Payment"
              type="money"
              value={formValues?.default_inviter_amount}
              onChange={(value) => {
                const formattedValue = value.charAt(0) === '$' ? value.slice(1) : value;
                setFormValues({ ...formValues, default_inviter_amount: formattedValue });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Inviter Award Type"
              choices={[
                { label: 'Guaranteed', value: 'guaranteed' },
                { label: 'Drawing', value: 'drawing' },
              ]}
              value={formValues.inviter_award_type}
              onChange={(value) => setFormValues({ ...formValues, inviter_award_type: value })}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              label="Default Invitee Payment"
              type="money"
              value={formValues?.default_invitee_amount}
              onChange={(value) => {
                const formattedValue = value.charAt(0) === '$' ? value.slice(1) : value;
                setFormValues({ ...formValues, default_invitee_amount: formattedValue });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              type="date"
              label="Start Date"
              value={formValues?.start_date}
              onChange={(value: string) => setFormValues({ ...formValues, start_date: value })}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              type="date"
              label="End Date"
              value={formValues?.end_date}
              onChange={(value: string) => setFormValues({ ...formValues, end_date: value })}
            />
          </Grid>
          {invitationOpportunity.id ? (
            <>
              <Grid item xs={6}>
                <FormInput
                  label="Referrals Completed"
                  value={invitationOpportunity?.invitations_completed?.toString()}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  label="Referral Quota"
                  value={formValues?.invitation_quota}
                  onChange={(value: string) => setFormValues({ ...formValues, invitation_quota: value })}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6}>
                <FormInput
                  label="Referral Quota"
                  value={formValues?.invitation_quota}
                  onChange={(value: string) => setFormValues({ ...formValues, invitation_quota: value })}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <SelectInput
              label="Status"
              value={formValues?.status}
              choices={[
                { label: 'Active', value: 'active' },
                { label: 'Inactive', value: 'inactive' },
              ]}
              onChange={(value: string) => setFormValues({ ...formValues, status: value })}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Automatically Update Status"
              choices={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              value={formValues.update_status}
              onChange={(value) => setFormValues({ ...formValues, update_status: value })}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Invitation Type"
              value={formValues?.invitation_type}
              choices={invitationTypeChoices}
              onChange={(value) => setFormValues({ ...formValues, invitation_type: value })}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              label="Learn More"
              rows={4}
              value={formValues.learn_more}
              onChange={(value) => setFormValues({ ...formValues, learn_more: value })}
              multiline
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Project"
              value={formValues?.project_id}
              choices={projectOptions}
              onChange={handleProjectChange}
            />
          </Grid>
          <Grid item xs={12}>
            {phaseOptions?.length > 0 && (
              <UngroupedAutocompleteInput
                label="Phases"
                choices={phaseOptions}
                onChange={(value) => {
                  setFormValues({ ...formValues, phase_names: value });
                }}
                value={formValues.phase_names}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Opportunity"
              value={formValues?.opportunity_id}
              choices={opportunityOptions}
              onChange={(value: string) => setFormValues({ ...formValues, opportunity_id: value })}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              label="Custom Query Parameter"
              placeholder="source=partner-name"
              value={formValues?.custom_query_parameter}
              onChange={(value: string) => setFormValues({ ...formValues, custom_query_parameter: value })}
            />
          </Grid>

          <Grid item xs={12}>
            <FormInput
              label="Sample Message"
              rows={4}
              value={formValues.sample_message}
              onChange={(value) => setFormValues({ ...formValues, sample_message: value })}
              multiline
            />
          </Grid>
        </Grid>
      </Card>
      <Card sectioned>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h2 className="Polaris-Heading">Social Media</h2>
          </Grid>
          <Grid item xs={12}>
            <FormInput
              label="Social Media Title"
              value={formValues?.social_media_title}
              onChange={(value) => setFormValues({ ...formValues, social_media_title: value })}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              label="Social Media Description"
              rows={3}
              value={formValues?.social_media_description}
              onChange={(value) => setFormValues({ ...formValues, social_media_description: value })}
              multiline
            />
          </Grid>
        </Grid>
        {formError && <p style={{ color: 'red', marginBottom: '8px' }}>{formError}</p>}
        <>
          {invitationOpportunity?.id ? (
            <>
              <Button primary onClick={handleUpdate}>
                Update Invitation Opportunity
              </Button>
              {invitationOpportunity?.invitations_completed === 0 && (
                <span style={{ marginLeft: '10px' }}>
                  <Button onClick={handleDelete}>Delete Invitation Opportunity</Button>
                </span>
              )}
            </>
          ) : (
            <Button primary onClick={handleCreate}>
              Create Invitation Opportunity
            </Button>
          )}
        </>
      </Card>
    </FormLayout>
  );
};

export default InvitationOpportunityForm;
