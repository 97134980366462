import React from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { DropzoneArea } from 'material-ui-dropzone';

import { InvitationOpportunity } from '../model';

import axios from '../../utils/axios.utils';

interface InvitationOpportunityImageDropZoneProps {
  invitationOpportunity: InvitationOpportunity;
  fetchInvitationOpportunity?: (opportunity_id: string) => Promise<void>;
  title: string;
  description: string;
  category: string;
  ratio: string;
}

const InvitationOpportunityImageDropZone: React.FC<InvitationOpportunityImageDropZoneProps> = (props) => {
  let { invitationOpportunity, fetchInvitationOpportunity, title, description, category, ratio } = props;

  const handleUpload = async (files: File[]) => {
    const data = new FormData();
    const attachedFile = files[0];

    if (attachedFile instanceof File === false) {
      return;
    }

    data.append('file', attachedFile, attachedFile.name);
    data.append('category', category);

    try {
      let response = await axios.post(`/invitation_opportunity_images?id=${invitationOpportunity.id}`, data);

      if (response?.statusText === 'OK') {
        fetchInvitationOpportunity(invitationOpportunity.id);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  let imageUrl = invitationOpportunity?.[`${category}_url`];

  return (
    <Card style={{ padding: '2%' }}>
      <Grid container>
        <Grid item xs={12}>
          <h3 className="Polaris-Heading">{title}</h3>
        </Grid>
        <Grid item xs={12}>
          <em>{description}</em>
        </Grid>
        <Grid item xs={12}>
          Aspect Ratio: {ratio}
        </Grid>
        <Grid item xs={5} style={{ padding: '2%' }}>
          <DropzoneArea
            acceptedFiles={['.jpg', '.jpeg', '.png']}
            filesLimit={1}
            dropzoneText="Drag and drop a .jpg or .png file here, or click to select"
            onChange={(files) => handleUpload(files)}
            maxFileSize={50000000}
            showAlerts
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2%' }}>
          {imageUrl ? (
            <img src={imageUrl} alt="Preview" style={{ width: 'auto', height: '200px' }} />
          ) : (
            <p>Upload an image to see the preview here.</p>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default InvitationOpportunityImageDropZone;
