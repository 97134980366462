import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import DownloadPicAndDescription from './DownloadPicAndDescription';

import { InvitationOpportunity } from '../model';

interface DownloadableImageRowProps {
  invitationOpportunity: InvitationOpportunity | undefined;
}

const DownloadableImageRow: React.FC<DownloadableImageRowProps> = (props) => {
  const { invitationOpportunity } = props;

  const imageCollection = [
    {
      image: invitationOpportunity.promotional_image_1_url,
      description: 'Instagram Static Post',
    },
    {
      image: invitationOpportunity.promotional_image_2_url,
      description: 'Stories Post',
    },
    {
      image: invitationOpportunity.promotional_image_3_url,
      description: 'Facebook/ X (fomerly Twitter)',
    },
    {
      image: invitationOpportunity.promotional_image_4_url,
      description: 'LinkedIn Square',
    },
  ];

  return (
    <Grid container style={{ padding: '20px' }}>
      {imageCollection.some((item) => item.image) && (
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Click on any of these images to download
          </Typography>
        </Grid>
      )}
      {imageCollection.map(
        (item, index) =>
          item.image && (
            <Grid item xs={12} md={3} key={`img-${index}`}>
              <DownloadPicAndDescription
                invitationOpportunity={invitationOpportunity}
                whichPic={item.image}
                description={item.description}
              />
            </Grid>
          ),
      )}
    </Grid>
  );
};

export default DownloadableImageRow;
