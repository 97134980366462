import React, { useState, useEffect, useContext } from 'react';
import { FormLayout } from '@shopify/polaris';
import { useParams } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import UngroupedAutocompleteInput from '../../form/components/UngroupedAutocompleteInput';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { ProjectPhasesContext } from '../contexts/ProjectPhasesContext';
import { Phase, PhaseAddress } from '../model';
import LoadingSpinner from '../../loading/components/LoadingSpinner';

interface ProjectPhaseFormProps {
  phase: Phase;
  phaseAddress?: PhaseAddress;
  afterCreate?: () => void;
}

const ProjectPhaseForm: React.FC<ProjectPhaseFormProps> = (props) => {
  const { phase, phaseAddress, afterCreate } = props;
  const [formOptionsFetched, setFormOptionsFetched] = useState(false);
  const { updatePhase, createPhase, phaseSubmitting, fetchFormOptions, formOptions, errorMessage } =
    useContext(ProjectPhasesContext);
  const { projectId } = useParams<Record<string, string>>();

  const [formValues, setFormValues] = useState<Phase>({});
  const [addressFormValues, setAddressFormValues] = useState<PhaseAddress>({});
  const [tremendousWarningMessage, setTremendousWarningMessage] = useState<string>('');

  const [editorState, setEditorState] = useState();

  useEffect(() => {
    if (phase?.respondent_criteria) {
      const blocksFromHtml = htmlToDraft(phase.respondent_criteria);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const initialState = EditorState.createWithContent(contentState);
      setEditorState(initialState);
    }
  }, [phase]);

  useEffect(() => {
    setFormValues({
      ...phase,
      project_id: phase?.project_id || projectId,
    });
  }, [phase, projectId]);

  useEffect(() => {
    setAddressFormValues({
      address_line1: phaseAddress?.address_line1,
      city: phaseAddress?.city,
      state: phaseAddress?.state,
      zip: phaseAddress?.zip,
    });
  }, [phaseAddress]);

  useEffect(() => {
    if (phase?.tremendous_campaign_id?.toString() !== formValues.tremendous_campaign_id?.toString()) {
      setTremendousWarningMessage(
        'Note: Changing the Campaign for a phase will automatically update the campaign for all of its incomplete payments',
      );
    } else {
      setTremendousWarningMessage('');
    }
  }, [formValues, phase]);

  useEffect(() => {
    if (!formOptionsFetched) {
      fetchFormOptions();
      setFormOptionsFetched(true);
    }
  }, [formOptionsFetched, fetchFormOptions]);

  const combinedFormValues = { ...formValues, address: addressFormValues };

  const handleUpdate = () => {
    updatePhase(phase.id, combinedFormValues);
  };

  const handleCreate = () => {
    if (afterCreate) {
      createPhase(combinedFormValues, afterCreate);
    } else {
      createPhase(combinedFormValues);
    }
  };

  return formOptions?.project_options ? (
    <FormLayout>
      <h1 className="Polaris-Heading">Phase Details</h1>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormInput
            label="Name"
            value={formValues.name}
            onChange={(value) => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Project"
            choices={formOptions.project_options}
            onChange={(value) => setFormValues({ ...formValues, project_id: value })}
            value={formValues.project_id}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            label="Start Date"
            type="date"
            value={formValues.start_date}
            onChange={(value) => setFormValues({ ...formValues, start_date: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            label="End Date"
            type="date"
            value={formValues.end_date}
            onChange={(value) => setFormValues({ ...formValues, end_date: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            label="Honorarium Fee"
            type="money"
            value={formValues.honorarium_fee}
            onChange={(value) => {
              const formattedValue = value.charAt(0) === '$' ? value.slice(1) : value;
              setFormValues({ ...formValues, honorarium_fee: formattedValue });
            }}
          />
          {phase?.id && formValues?.honorarium_fee !== phase?.honorarium_fee && (
            <p style={{ color: 'red' }}>
              Reminder: Updating the honorarium fee will update the payment amount for all scheduled interviews attached
              to this phase.
            </p>
          )}
        </Grid>
        <Grid item xs={6}>
          <FormInput
            label="Travel Amount"
            type="money"
            value={formValues.travel_amount}
            onChange={(value) => {
              const formattedValue = value.charAt(0) === '$' ? value.slice(1) : value;
              setFormValues({ ...formValues, travel_amount: formattedValue });
            }}
          />
          <small>
            <em>
              If a number is entered here, then all completed interviews will automatically trigger a travel stipend.
            </em>
          </small>
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Homework"
            choices={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            value={formValues.homework}
            onChange={(value) => setFormValues({ ...formValues, homework: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Status"
            choices={[
              { label: 'Draft', value: 'draft' },
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' },
            ]}
            value={formValues.status}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Automatically Update Status"
            choices={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            value={formValues.update_status}
            onChange={(value) => setFormValues({ ...formValues, update_status: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Automatically Import Respondents"
            choices={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            value={formValues.import_respondents}
            onChange={(value) => setFormValues({ ...formValues, import_respondents: value })}
          />
        </Grid>
        <Grid item xs={6}>
          {formValues.insighter_role_names && (
            <UngroupedAutocompleteInput
              label="Filtered Roles For Import"
              choices={formOptions?.insighter_role_options}
              onChange={(value) => {
                setFormValues({ ...formValues, insighter_role_names: value });
              }}
              value={formValues.insighter_role_names}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Location Type"
            choices={[
              { label: 'Virtual', value: 'virtual' },
              { label: 'In-Person', value: 'in_person' },
            ]}
            value={formValues.location}
            onChange={(value) => setFormValues({ ...formValues, location: value })}
          />
        </Grid>
        {formValues.location === 'in_person' && (
          <>
            <Grid item xs={6}>
              <FormInput
                label="Address"
                value={addressFormValues.address_line1}
                onChange={(value) => setAddressFormValues({ ...addressFormValues, address_line1: value })}
              />
            </Grid>
            <Grid item xs={4}>
              <FormInput
                label="City"
                value={addressFormValues.city}
                onChange={(value) => setAddressFormValues({ ...addressFormValues, city: value })}
              />
            </Grid>
            <Grid item xs={4}>
              <FormInput
                label="State"
                value={addressFormValues.state}
                onChange={(value) => setAddressFormValues({ ...addressFormValues, state: value })}
              />
            </Grid>
            <Grid item xs={4}>
              <FormInput
                label="Zip"
                value={addressFormValues.zip}
                onChange={(value) => setAddressFormValues({ ...addressFormValues, zip: value })}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(value) => {
              setEditorState(value);
              setFormValues({
                ...formValues,
                respondent_criteria: draftToHtml(convertToRaw(value.getCurrentContent())).replaceAll(
                  '<p></p>',
                  '<br />',
                ),
              });
            }}
            toolbar={{
              inline: { inDropdown: false },
              list: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['unordered', 'ordered'],
              },
              textAlign: { inDropdown: true },
              history: { inDropdown: false },
            }}
          />
        </Grid>
      </Grid>
      <br />
      <h1 className="Polaris-Heading">Additional Phase Information</h1>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SelectInput
            label="Interview Category"
            choices={[
              { label: 'Virtual Qualitative', value: 'Virtual Qualitative' },
              { label: 'Virtual Quantitative', value: 'Virtual Quantitative' },
              { label: 'In-Person Qualitative', value: 'In-Person Qualitative' },
              { label: 'Brand Partnership', value: 'Brand Partnership' },
            ]}
            value={formValues.interview_category}
            onChange={(value) => setFormValues({ ...formValues, interview_category: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Insighter Category"
            choices={[
              { label: 'HCC', value: 'HCC' },
              { label: 'HCP', value: 'HCP' },
              { label: 'Caregiver', value: 'Caregiver' },
              { label: 'Brand Partner', value: 'Brand Partner' },
            ]}
            value={formValues.insighter_category}
            onChange={(value) => setFormValues({ ...formValues, insighter_category: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Lead Recruiter"
            choices={formOptions.project_manager_options}
            value={formValues.recruiter_id}
            onChange={(value) => setFormValues({ ...formValues, recruiter_id: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="TI Recruiter"
            choices={formOptions.ti_recruiter_options}
            value={formValues.ti_recruiter_id}
            onChange={(value) => setFormValues({ ...formValues, ti_recruiter_id: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Lead Moderator"
            choices={formOptions.project_manager_options}
            value={formValues.moderator_id}
            onChange={(value) => setFormValues({ ...formValues, moderator_id: value })}
          />
        </Grid>
      </Grid>
      <hr />
      <h1 className="Polaris-Heading">Tremendous Details</h1>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SelectInput
            label="Tremendous Campaign"
            choices={formOptions.tremendous_campaign_options}
            value={formValues.tremendous_campaign_id}
            onChange={(value) => setFormValues({ ...formValues, tremendous_campaign_id: value })}
          />
        </Grid>
      </Grid>
      <p style={{ color: 'red ' }}>{tremendousWarningMessage}</p>
      <hr />
      <h1 className="Polaris-Heading">Calendly & Qualtrics Details</h1>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormInput
            label="Respondent Quota"
            value={formValues.respondent_quota}
            onChange={(value) => setFormValues({ ...formValues, respondent_quota: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            label="Qualtrics Survey ID"
            value={formValues.qualtrics_survey_id}
            onChange={(value) => setFormValues({ ...formValues, qualtrics_survey_id: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Qualtrics Survey Owner"
            choices={[
              { label: 'tena@sommerconsulting.com', value: 'tena@sommerconsulting.com' },
              { label: 'terri@sommerconsulting.com', value: 'terri@sommerconsulting.com' },
              { label: 'alyssa@sommerconsulting.com', value: 'alyssa@sommerconsulting.com' },
              { label: 'amy@sommerconsulting.com', value: 'amy@sommerconsulting.com' },
              { label: 'maddison@sommerconsulting.com', value: 'maddison@sommerconsulting.com' },
              { label: 'nate@sommerconsulting.com', value: 'nate@sommerconsulting.com' },
            ]}
            value={formValues.qualtrics_survey_owner}
            onChange={(value) => setFormValues({ ...formValues, qualtrics_survey_owner: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label="Calendly Event Name"
            choices={formOptions.calendly_event_types}
            value={formValues.calendly_event_type_id}
            onChange={(value) => setFormValues({ ...formValues, calendly_event_type_id: value })}
          />
        </Grid>
      </Grid>
      {errorMessage?.length > 0 && <p style={{ color: 'red', whiteSpace: 'break-spaces' }}>{errorMessage}</p>}
      <>
        {phase?.id ? (
          <Button variant="contained" className="primary-btn" disabled={phaseSubmitting} onClick={handleUpdate}>
            Update Phase
          </Button>
        ) : (
          <Button variant="contained" className="primary-btn" disabled={phaseSubmitting} onClick={handleCreate}>
            Create Phase
          </Button>
        )}
      </>
    </FormLayout>
  ) : (
    <LoadingSpinner />
  );
};

export default ProjectPhaseForm;
