import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Badge, Layout, Loading, Page, Toast } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import InvitationPartnerForm from '../components/InvitationPartnerForm';
import FormSkeleton from '../../loading/components/FormSkeleton';
import { InvitationPartnersContext } from '../contexts/InvitationPartnersContext';
import BackButton from '../../navigation/components/BackButton';
import todaysDate from '../../utils/dateFunctions';

const EditInvitationPartner: React.FunctionComponent = () => {
  const { invitationPartnerId, invitationOpportunityId } = useParams<Record<string, string>>();

  const [invitationPartnerFetched, setInvitationPartnerFetched] = React.useState(false);
  const { invitationPartner, fetchInvitationPartner, invitationPartnerLoading, invitationsDownload } =
    React.useContext(InvitationPartnersContext);

  React.useEffect(() => {
    if (!invitationPartnerFetched) {
      fetchInvitationPartner(invitationPartnerId);
      setInvitationPartnerFetched(true);
    }
  }, [invitationPartnerFetched, setInvitationPartnerFetched, fetchInvitationPartner, invitationPartnerId]);

  const [toastActive, setToastActive] = React.useState(false);
  const toggleToastActive = React.useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;
  const loadingMarkup = invitationPartnerLoading ? <Loading /> : null;

  const actualPageMarkup = (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <BackButton pageAction={false} />
        </Grid>
        <Grid item style={{ marginTop: 20, marginRight: 20 }}>
          {invitationsDownload?.length > 0 ? (
            <CSVLink
              data={invitationsDownload}
              filename={`${invitationPartner?.insighter_name}-${todaysDate()}.csv`}
              style={{ textDecoration: 'none' }}
            >
              <Button primary>Download Invitations</Button>
            </CSVLink>
          ) : (
            <Button disabled>No Invitations</Button>
          )}
        </Grid>
      </Grid>
      <Page fullWidth title="Edit Referral Partner">
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <InvitationPartnerForm invitationPartner={invitationPartner} />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );

  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = invitationPartnerLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default EditInvitationPartner;
