export const paymentCategoryChoices = [
  { label: 'Insighter Payment - Honorarium', value: 'insighter_payment' },
  { label: 'Insighter Payment - Travel Stipend', value: 'insighter_travel' },
  { label: 'Insighter Payment - Brand Partner', value: 'insighter_brand_partner' },
  { label: 'Insighter Payment - Referral Program', value: 'referral_program' },
  { label: 'Insighter Payment - Other', value: 'insighter_other' },
  { label: 'Qualtrics Programming', value: 'qualtrics_programming' },
];

export const payoutTypeChoices = [
  { label: 'Tremendous', value: 'tremendous' },
  { label: 'Check (Mail)', value: 'mail' },
  { label: 'Check (In-person)', value: 'in-person' },
];
