import React from 'react';
import { Button, Card } from '@shopify/polaris';
import { CSVLink } from 'react-csv';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import InvitationRowMenu from '../../invitations/components/InvitationRowMenu';
import { Invitation, InvitationOpportunity } from '../../invitations/model';
import { InvitationsContext } from '../../invitations/contexts/InvitationsContext';
import InvitationsFilterBar from '../../invitations/components/InvitationsFilterBar';

export const invitationColumns: IDataTableColumn<any>[] = [
  {
    name: 'Inviter Name',
    selector: 'inviter_name',
    sortable: true,
    cell: (row) => (
      <div>
        <a style={{ color: '#1D1D1D' }} href={`/admin/insighters/${row.inviter_id}`}>
          {row.inviter_name}
        </a>
      </div>
    ),
  },
  {
    name: 'Invitee Name',
    selector: 'invitee_name',
    sortable: true,
    cell: (row) => (
      <div>
        <a style={{ color: '#1D1D1D' }} href={`/admin/insighters/${row.invitee_id}`}>
          {row.invitee_name}
        </a>
      </div>
    ),
  },
  {
    name: 'Invitee Email',
    selector: 'invitee_email',
    sortable: true,
  },
  {
    name: 'Project Name',
    selector: 'project_name',
    // sortable: true,
  },
  {
    name: 'Phase Name',
    selector: 'phase_name',
    // sortable: true,
  },
  {
    name: 'Date Created',
    selector: 'created_at',
    sortable: true,
  },
  {
    name: 'Invitation Amount',
    selector: 'inviter_amount',
    sortable: true,
    cell: ({ inviter_amount }): React.ReactNode => <div>${inviter_amount}</div>,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: (row): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color:
            ['complete', 'survey complete'].includes(row.status) || row.invitation_opportunity_id === 30
              ? 'green'
              : 'red',
        }}
      >
        {row.invitation_opportunity_id === 30 ? 'Sign-Up Complete' : row.status}
      </div>
    ),
  },
  {
    cell: (invitation: Invitation) => <InvitationRowMenu invitation={invitation} />,
    name: 'ACTIONS',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

interface InvitationsCreatedProps {
  invitationOpportunity: InvitationOpportunity;
}

const InvitationsCreated: React.FC<InvitationsCreatedProps> = (props) => {
  const { invitationOpportunity } = props;
  const {
    invitations,
    fetchInvitationsForOpportunityDetail,
    totalRecords,
    invitationsDownload,
    invitationsLoading,
    filterValues,
    setFilterValues,
    updateFilterValues,
  } = React.useContext(InvitationsContext);
  const [invitationsFetched, setInvitationsFetched] = React.useState(false);

  React.useEffect(() => {
    updateFilterValues(invitationOpportunity.id);

    if (!invitationsFetched && invitationOpportunity?.id) {
      let invitationFilters = sessionStorage.getItem(`invitationFilters-${invitationOpportunity.id}`);

      if (!invitationFilters) {
        invitationFilters = `ransack[invitation_opportunity_id_in]=${invitationOpportunity.id}`;
      }
      fetchInvitationsForOpportunityDetail(invitationFilters);
      setInvitationsFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationsFetched, invitationOpportunity]);

  const handlePageChange = (page: number) => {
    const pageParams = {
      page: `page=${page}`,
      queryFilter: `ransack[query]=${filterValues.query}`,
      invitationOpportunityFilter: `ransack[invitation_opportunity_id_in]=${invitationOpportunity.id}`,
      sort: `sort=${filterValues.sort}`,
    };

    fetchInvitationsForOpportunityDetail(Object.values(pageParams).join('&'));
  };

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      inviter_name: 'inviter_user_last_name',
      invitee_name: 'invitee_user_last_name',
      invitee_email: 'invitee_user_email',
      created_at: 'created_at',
      inviter_amount: 'inviter_amount',
      status: 'status',
    };

    const sortKey = columnNamesToRansack[column.selector];
    setFilterValues({ ...filterValues, sort: `${sortKey} ${sortDirection}` });

    const filterParams = {
      queryFilter: `ransack[query]=${filterValues.query}`,
      invitationOpportunityFilter: `ransack[invitation_opportunity_id_in]=${invitationOpportunity.id}`,
      sort: `sort=${sortKey} ${sortDirection}`,
    };

    fetchInvitationsForOpportunityDetail(Object.values(filterParams).join('&'));
  };

  return (
    <>
      <Card sectioned>
        <div style={{ marginBottom: 15 }}>
          <div className="Polaris-Stack Polaris-Stack--alignmentBaseline">
            <div className="Polaris-Stack__Item Polaris-Stack__Item--fill">
              <h2 className="Polaris-Heading">Invitations Created</h2>
            </div>
            <div className="Polaris-Stack__Item">
              <div className="Polaris-ButtonGroup">
                <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                  {invitationsDownload && (
                    <CSVLink
                      data={invitationsDownload}
                      filename={`Referral Partners for ${invitationOpportunity.name}`}
                      style={{ textDecoration: 'none', marginTop: 25, marginRight: 15 }}
                    >
                      <Button primary>Download Invitations</Button>
                    </CSVLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <InvitationsFilterBar />
        <DataTable
          columns={invitationColumns}
          data={invitations}
          pagination
          striped
          highlightOnHover
          pointerOnHover
          noHeader
          paginationServer
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25]}
          paginationTotalRows={totalRecords}
          onChangePage={handlePageChange}
          sortServer
          onSort={handleColumnSort}
          progressPending={invitationsLoading}
        />
      </Card>
    </>
  );
};

export default InvitationsCreated;
