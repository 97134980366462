import React from 'react';
import { Grid } from '@material-ui/core';
import { FormLayout, Card } from '@shopify/polaris';
import SelectInput from '../../form/components/SelectInput';
import { InvitationsContext } from '../contexts/InvitationsContext';
import { Invitation, InvitationFormValues } from '../model';
import ModalButton from '../../modal/components/ModalButton';

interface InvitationFormProps {
  invitation?: Invitation;
  afterAction?: () => void;
}

const InvitationForm: React.FC<InvitationFormProps> = (props) => {
  const { invitation, afterAction } = props;
  const { updateInvitation, invitationSubmitting, formOptions } = React.useContext(InvitationsContext);
  const [formValues, setFormValues] = React.useState<InvitationFormValues>({});

  React.useEffect(() => {
    setFormValues({
      status: invitation?.status,
      phase_id: invitation?.phase_id,
      inviter_name: invitation?.inviter_name,
      invitee_name: invitation?.invitee_name,
      project_name: invitation?.project_name,
      created_at: invitation?.created_at,
      inviter_amount: invitation?.inviter_amount,
    });
  }, [invitation]);

  const handleUpdate = () => {
    if (afterAction) {
      updateInvitation(invitation.id, formValues, afterAction);
    } else {
      updateInvitation(invitation.id, formValues);
    }
  };

  return (
    <FormLayout>
      <h1 className="Polaris-Heading">Invitation Details</h1>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SelectInput
            label="Phase"
            choices={formOptions?.phase_options}
            value={formValues.phase_id}
            onChange={(value) => setFormValues({ ...formValues, phase_id: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Status"
            choices={[
              { label: 'Application Pending', value: 'application pending' },
              { label: 'Opportunity Booked', value: 'opportunity booked' },
              { label: 'Payment Pending', value: 'payment pending' },
              { label: 'Complete', value: 'complete' },
              { label: 'Referral Expired', value: 'referral expired' },
              { label: 'Survey In-Progress', value: 'survey in progress' },
              { label: 'Survey Complete', value: 'survey complete' },
            ]}
            value={formValues.status}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <p>
            <b>Inviter Name</b>
          </p>
          <p>{formValues.inviter_name}</p>
        </Grid>
        <Grid item xs={6}>
          <p>
            <b>Invitee Name</b>
          </p>
          <p>{formValues.invitee_name}</p>
        </Grid>
        <Grid item xs={6}>
          <p>
            <b>Project Name</b>
          </p>
          <p>{formValues.project_name}</p>
        </Grid>
        <Grid item xs={6}>
          <p>
            <b>Date Created</b>
          </p>
          <p>{formValues.created_at}</p>
        </Grid>
        <Grid item xs={6}>
          <p>
            <b>Invitation Amount</b>
          </p>
          <p>${formValues.inviter_amount}</p>
        </Grid>
      </Grid>
      <br />
      <>
        <ModalButton theme="primary" onClick={handleUpdate} text="Update Invitation" loading={invitationSubmitting} />
      </>
    </FormLayout>
  );
};

export default InvitationForm;
