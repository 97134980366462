import React from 'react';
import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles, createStyles } from '@material-ui/core';
import HeroSection from './components/HeroSection';
import Logo from '../../../assets/logo.png';
import GotAQuestion from './components/GotAQuestion';
import RedesignFooter from '../common/RedesignFooter';

const useStyles = makeStyles((theme) =>
  createStyles({
    accordionHeader: {
      fontSize: '16px',
      fontWeight: 'bold',
    },

    bulletedList: {
      paddingLeft: '16px',
      paddingRight: '16px',
      margin: 0,
    },

    container: {
      marginBottom: '20px',
    },

    gridItem: {
      padding: '0 7.5px 30px 7.5px',
    },

    paragraph: {
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: '16px',
    },

    title: {
      fontSize: '40px',
      lineHeight: '40px',
      fontFamily: 'Cooper',
      margin: '30px 0',
      textAlign: 'center',

      [theme.breakpoints.up(500)]: {
        fontSize: '54px',
        lineHeight: '54px',
        margin: '40px 0',
      },
    },

    seperator: {
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: '#3b4255',
      maxWidth: '60px',
      margin: '40px auto',
    },

    subheader: {
      fontFamily: 'Cooper',
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '24px',
      marginBottom: '20px',
      textAlign: 'left',

      [theme.breakpoints.up(500)]: {
        fontSize: '32px',
        lineHeight: '32px',
      },
    },
  }),
);

const FrequentlyAskedQuestions: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div
      className="Polaris-Page Polaris-Page--fullWidth"
      style={{ position: 'relative', minHeight: '100vh', padding: 0 }}
    >
      <Grid item xs={12}>
        <HeroSection />
      </Grid>

      <Grid container justifyContent="center">
        <Grid item xs={10} md={8}>
          <Grid container>
            <Grid item md={12} xs={12} className={classes.gridItem}>
              <div className={classes.container}>
                <h2 className={classes.subheader}>The Insighters® Platform</h2>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>What is The Insighters®?</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          The Insighters® is a platform that connects you (your story, your experience, & your insights)
                          to paid opportunities sponsored by companies in healthcare & life sciences, technology, and
                          even government agencies.
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraph}>
                          Fill out your profile as completely as possible to ensure that you’re connected to the paid
                          opportunities that are the best fit for your insights.
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>
                      What types of paid opportunities does The Insighters® offer?
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          We offer 1-on-1 interviews, focus groups, surveys, virtual & in- person research, & more.
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraph}>
                          We also offer regular in-house opportunities & contests that are open to everyone (because
                          your feedback helps make The Insighters® better, too).
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>
                      How does joining a paid opportunity help me or my community?
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          Have your voice be heard! You (yes you!) can directly impact the next generation of healthcare
                          treatment, technology, & messaging development.
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraph}>
                          Learn & give your opinions about the latest & greatest in healthcare innovations or new
                          technologies.
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraph}>Get paid to share your story, experience, & insights!</p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>
                      Are all new paid opportunities posted on The Insighters® website?
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          Most opportunities with The Insighters® are posted on the website.
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraph}>
                          But sometimes we need to quickly connect individual Insighters to a last-minute opportunity.
                          <b>
                            {' '}
                            Completely filling out your profile with The Insighters® gives you special access to
                            additional paid opportunities!
                          </b>
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>How do I apply for an opportunity?</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          Sign up & fill out your profile with The Insighters®. Your profile information is how we reach
                          out to connect you to new opportunities that might be a good fit.
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraph}>
                          See an open opportunity that we haven’t matched you to? Consider applying anyway (you know
                          your own experiences best, after all).
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>Who sponsors the research opportunities?</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          The Insighters® research opportunities are sponsored by companies & non-profits connected to
                          healthcare & life sciences —pharmaceutical & biotech companies, digital start-ups, patient
                          advocacy organizations, & more.
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
            <Grid item md={12} xs={12} className={classes.gridItem}>
              <div className={classes.container}>
                <h2 className={classes.subheader}>Research Consent</h2>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>
                      How do you handle consent during a research opportunity?
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          We believe in enthusiastic & affirmative consent (both in research & in life). At every step
                          of the research process, we will ask you to confirm & consent that you’d like to keep going.
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>
                      I started my interview, but then changed my mind halfway through. Now what?
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          You are free to leave the research at any time—before your interview or at any time during the
                          interview—for any reason.
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
            <Grid item md={12} xs={12} className={classes.gridItem}>
              <div className={classes.container}>
                <h2 className={classes.subheader}>Confidentiality</h2>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>How is my information kept safe?</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          When it comes to privacy, we take your data protection very seriously & uphold strict industry
                          standards—both through The Insighters® platform and during your entire research experience.
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>
                      What happens to my information if I apply for a research opportunity that isn’t a good fit?
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          If you give us permission, we will keep your information so that we can be in touch for future
                          studies.
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraph}>
                          If you don’t give us permission, we will remove your information as soon as the research
                          opportunity closes.
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>How is my information shared?</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          Your insights are combined with others’ insights to keep your results anonymous. Your name or
                          identifiable information are never tied to the final research results.
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraph}>
                          Anonymized results of the research are shared internally with the company that has requested
                          research from The Insighters®.
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraph}>
                          Sometimes, a company will allow The Insighters® to share some anonymized results back to The
                          Insighters® community or to advocacy groups & non-profits. We love it when we can share
                          insights back with you!
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>
                      I see that my interview will be recorded—how do you keep my information private in a recording?
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          Our moderator will start your interview by reminding you that your conversation together is
                          being recorded & to please not say your full name or any specific identifying details (like
                          exactly where you live). We are passionate about maintaining your privacy.
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>Is any of my personally identifying information sold?</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          We never sell your personally identifiable information to anyone.
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
            <Grid item md={12} xs={12} className={classes.gridItem}>
              <div className={classes.container}>
                <h2 className={classes.subheader}>Scheduling</h2>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>
                      I applied for the research & it looks like I’m a good fit! How do I get scheduled?
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          <b>If we still need to confirm a few final details</b>, one of our recruiters will reach out
                          to you soon & find an interview time that is good for you.
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraph}>
                          <b>If we don’t need to confirm any final details</b>, you will receive a Calendly link at the
                          end of the research application. Calendly is an online scheduling tool where you can
                          self-schedule at a time that is good for you
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>
                      I’m booked for an interview. Who will I be chatting with?
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          You’ll be chatting with one of our highly skilled interviewers. You will be in excellent
                          hands—our moderators are known for their warmth, friendliness, & utmost respect for your story
                          & experience.
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>Something came up—can I reschedule my interview?</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          We still hope to hear from you & do our best to ensure that you can reschedule. We understand
                          that this might not always be possible for you—of course, we hope to connect with you another
                          time!
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
            <Grid item md={12} xs={12} className={classes.gridItem}>
              <div className={classes.container}>
                <h2 className={classes.subheader}>Payment</h2>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>When will I be paid?</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          After fully completing your opportunity, your payment will be sent out fast—typically within
                          just a few business days.
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>How will I be paid?</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          We&apos;re using{' '}
                          <a href="https://www.tremendous.com/terms" target="_blank" rel="noreferrer">
                            Tremendous
                          </a>{' '}
                          which makes it easy for you to choose your payment type—Paypal, Venmo, direct deposit, gift
                          cards, and more! You will receive an email from The Insighters® by Tremendous once your
                          payment is available to you. Important! Certain payment methods have different expiration
                          dates. You must collect your full payment before your chosen payment&apos;s expiration date.
                          Please see our updated Terms of Service for full details.
                        </p>
                        <p>
                          <i>
                            Important! Certain payment methods have different expiration dates. You must collect your
                            full payment before your chosen payment&apos;s expiration date. Please see our updated{' '}
                            <a href="https://www.theinsighters.com/terms-of-use" target="_blank" rel="noreferrer">
                              Terms of Service
                            </a>{' '}
                            for full details.
                          </i>
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>How do I know that my payment information is safe?</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          Your general payment information is protected by Tremendous, as well as the payout provider
                          that you select for your specific payment.
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>Can I donate my payment?</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          Yes! Just let us know if you prefer to donate your payment to your favorite health advocacy
                          group or organization. We will make it happen.
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
            {/* Note: These 'Inviting Others' questions are also included in the /invitations page.
            If these are updated here, they should also be updated in the other page. */}
            <Grid item md={12} xs={12} className={classes.gridItem}>
              <div className={classes.container}>
                <h2 className={classes.subheader}>Inviting Others</h2>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>
                      I sent out some personalized invitations. What happens next?
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ol className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          Your invitees will be invited to book an interview if the research is a good fit.
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraph}>
                          Their application status will change from PENDING to BOOKED if they schedule an interview.
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraph}>
                          Their application status will change to COMPLETE if they finish an interview. You will receive
                          an email that a payment is on its way to you.
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraph}>
                          Their application status will change to REFERRAL EXPIRED if they do not book & research has
                          wrapped up.
                        </p>
                      </li>
                    </ol>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>
                      I have invitees that did not complete an interview. What happened?
                    </h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid xs={12}>
                        <p className={classes.paragraph}>
                          Here are some common reasons why your invitee might not complete an interview.
                        </p>
                      </Grid>

                      <Grid xs={12}>
                        <ul className={classes.bulletedList}>
                          <li>
                            <p className={classes.paragraph}>The opportunity was not quite the right fit.</p>
                          </li>
                          <li>
                            <p className={classes.paragraph}>Availability during research changed.</p>
                          </li>
                          <li>
                            <p className={classes.paragraph}>We were unable to get in touch with them.</p>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>

            <Grid item md={12} xs={12} className={classes.gridItem}>
              <div className={classes.container}>
                <h2 className={classes.subheader}>Security</h2>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3 className={classes.accordionHeader}>How do you keep my profile & information secure?</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className={classes.bulletedList}>
                      <li>
                        <p className={classes.paragraph}>
                          At The Insighters®, we understand it is critically important that we protect your data. Our
                          infrastructure and applications are created with industry-standard security measures and our
                          engineering team continuously monitors all our systems to ensure protection from any data
                          vulnerability.
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraph}>
                          Your information is secure as it moves into and within our system. All transmissions are
                          encrypted using industry-standard SSL (Secure Sockets Layer), which establishes an encrypted
                          link between our servers and your web browser. Additionally, data within our system is
                          accessible only by “need to know” authorized employees.
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraph}>
                          Finally, we agree that your data is yours – not ours. We never sell your data with customers
                          or other third parties. You control your data and can change or delete it at your convenience.
                        </p>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid item xs={12}>
        <GotAQuestion />
      </Grid>
      <Grid item xs={12}>
        <RedesignFooter />
      </Grid>
    </div>
  );
};

export default FrequentlyAskedQuestions;
